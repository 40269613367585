import React from 'react';
import { Routes ,Route } from 'react-router-dom';
import Home from '../../page/Home';
import MoviePlayer from '../../page/MoviePlayer';
import Categories from '../../page/Categories';
import MoviesForGenres from '../../page/MoviesForGenres';
import SearchBox from '../../page/SearchBox';
import EpisodeSeries from '../../page/EpisodeSeries';
import SeriePlayer from '../../page/SeriePlayer';
import Collection from '../../page/Collection';
import CollectionPlayer from '../../page/CollectionPlayer';
import CollectionPlayerMobile from '../../page/CollectionPlayerMobile';

//Faz o Redirecionamento das paginas
export default () =>{
	return(
	  	<Routes>
			<Route exact path="/" element={<Home/>} />
			<Route exact path="/categorias" element={<Categories/>} />
			<Route exact path="filme/:id" element={<MoviePlayer/>} />
			<Route exact path="/serie/:id/temporada/:temp/episodio/:epid" element={<SeriePlayer/>} />
			<Route exact path="serie/:id" element={<EpisodeSeries/>} />
			<Route exact path="categorias/:idgenero" element={<MoviesForGenres/>} />
			<Route exact path="categorias/:idgenero/:id" element={<MoviePlayer/>} />
			<Route exact path="/buscar" element={<SearchBox/>} />
			<Route exact path="/buscar/:id" element={<MoviePlayer/>} />
			<Route exact path="/colecoes" element={<Collection/>} />	
			<Route exact path="/colecoes/:id" element={<CollectionPlayer/>} />
			<Route exact path="/colecoes/m/:id/:movie" element={<CollectionPlayerMobile/>} />		
	    </Routes>		
	);
}