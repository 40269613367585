import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Header from './page/Header';
import PagesManager from './components/PagesManager';
import Footer from './page/Footer';
import TaskCron from './components/TaskCron';

function App() {

  return (
    <BrowserRouter>
      <TaskCron />
      <Header />
      <PagesManager />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
