import React, {useEffect, useState} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Container, Header, Content, ImageContainer, Image, TextContainer } from './style';
import localforage from "localforage";

//gera as categorias dos filmes
function Categories() {

    const [list, setList] = useState([]);
  
    useEffect(() => {
        const fetchList = async () => {

            //Buscar as informaçoes dos filmes e dos generos no tmdb
            const genresTmdb = await axios.get(`${process.env.REACT_APP_API_BASE_TMDB}/genre/movie/list?language=pt-BR`, {
                headers: {
                  'Authorization': process.env.REACT_APP_API_AUTH_TMDB,
                  'accept': 'application/json'
                }
            });

            const listGenres = genresTmdb.data.genres;

            const cacheMovies = await localforage.getItem('cacheMovies');
            if (cacheMovies != null) {
                setList(JSON.parse(cacheMovies));
            }

            const moviesforGenres = [];

            for (const genre of listGenres) {
                const moviesTmdb = await axios.post(`${process.env.REACT_APP_API_BASE_LIONFLIX}/filmes/genres`,{
                    genre: genre.id
                }, {
                    headers: {
                      'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });

                if (moviesTmdb.data.length > 0) {
                    // Adicione o primeiro filme do gênero à lista de filmesPorGenero
                    moviesforGenres.push({
                    //   genre: genre.name,
                    //   movie: moviesTmdb.data[0],
                      generoID: genre.id,
                      generoName: genre.name,
                      filmes: moviesTmdb.data[0].Backdrop                      
                    });
                }
            }

            localforage.removeItem('cacheMovies');
            localforage.setItem('cacheMovies', JSON.stringify(moviesforGenres));
            setList(JSON.parse(cacheMovies));
        };

        fetchList();


        const interval = setInterval(() => {
            fetchList();
        }, 15000);
        return () => clearInterval(interval);        
    },[]);

    //console.log(list)

    //movies random index.filmes[Math.floor(Math.random() * index.filmes.length)]
	const categorias = list?.map((index,key) =>
        <Link to={`${index.generoID}`} key={key} className="table-button table-oppacity-button">
            <ImageContainer>
                <Image key={key} src={`https://image.tmdb.org/t/p/w400${index.filmes}`}/>
                <TextContainer>
                    <h2>{index.generoName}</h2>
                </TextContainer>
            </ImageContainer>			
        </Link>  			
	);    

    return(
        <Container>
            <Header>Gêneros</Header>
            <Content>
                {categorias}
            </Content>
        </Container>
    );
}

export default Categories;