import styled from 'styled-components';

export const MovieCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MovieImage = styled.img`
  width: 150px;
  height: 225px;
  object-fit: cover;
  padding: 5px;

  @media screen and (max-width: 480px) {
    /* Estilos para telas menores que 481px (exemplo: dispositivos móveis em modo retrato) */
    width: 90px;
    height: 113px;
  }
`;