import React, { useRef } from 'react';
import { MovieListWrapper, ArrowButton } from './style';
import Cards from '../Cards';

function Lists({ list }) {
  const listRef = useRef(null);

  const handleScrollLeft = () => {
    listRef.current.scrollLeft -= 300;
  };

  const handleScrollRight = () => {
    listRef.current.scrollLeft += 300;
  };

  return (
    <div style={{ position: 'relative' }}>
      <MovieListWrapper ref={listRef}>
        {list && list.map((itemList) => (
          <Cards key={itemList.ID == null ? itemList.id : itemList.ID } item={itemList} />
        ))}
      </MovieListWrapper>
      <ArrowButton direction="left" onClick={handleScrollLeft}>
        {'<'}
      </ArrowButton>
      <ArrowButton direction="right" onClick={handleScrollRight}>
        {'>'}
      </ArrowButton>
    </div>
  );
}

export default Lists;