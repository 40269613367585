import React, {useEffect, useState} from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Banner, Container, TxtContainer, Text, PlayerContainer, Player, Button, BtnPreviouNext } from "./style";

function CollectionPlayerMobile() {

    const {id, movie} = useParams();
    const navigate = useNavigate();

    const [title, setTitle] = useState('');
    const [currentMovie, setCurrentMovie] = useState([]);
    const [selectedOption, setSelectedOption] = useState(movie);
    const [totalMovies, setTotalMovies] = useState(0);
    
    useEffect(() => {
        const fetchLists = async (id, selected) => {
            const collection = await axios.get(`${process.env.REACT_APP_API_BASE_LIONFLIX}/filmes/collection`, {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            const response = collection.data[id - 1];

            setTitle(response?.title);
            setCurrentMovie(response?.info[selected]);
            setTotalMovies(response?.info.length - 1);
        };

        fetchLists(id, selectedOption);

    },[id, selectedOption]);

    const handlePrevious = () => {
        if (parseInt(selectedOption) > 0) {
            setSelectedOption(parseInt(selectedOption) - 1);
            navigate(`/colecoes/m/${id}/${parseInt(selectedOption) - 1}`);
        }
    };
    
    const handleNext = () => {
        if (parseInt(selectedOption) < parseInt(totalMovies)) {
            setSelectedOption(parseInt(selectedOption) + 1);
            navigate(`/colecoes/m/${id}/${parseInt(selectedOption) + 1}`);
        }
    };   

    return(
        <Banner style={{ backgroundImage: `url(https://image.tmdb.org/t/p/original${currentMovie?.Backdrop})` }}>
            <Container>
                <TxtContainer><Button to={`/colecoes/${id}`} >Retornar para coleção {title}</Button></TxtContainer>
                <PlayerContainer>
                    {(currentMovie?.Idade === "Livre") ? <Text>{currentMovie?.NameMovie} ({currentMovie?.Idade})</Text> : <Text>{currentMovie?.NameMovie} (+{currentMovie?.Idade})</Text>}
                    <Player src={currentMovie?.LinkMovie} frameBorder='0' allowFullScreen></Player>
                    <BtnPreviouNext onClick={handlePrevious} disabled={parseInt(selectedOption) === 0}>Anterior</BtnPreviouNext>
                    <BtnPreviouNext onClick={handleNext} disabled={parseInt(selectedOption) === parseInt(totalMovies)}>Proximo</BtnPreviouNext>
                </PlayerContainer>
            </Container>
        </Banner> 
    );
}

export default CollectionPlayerMobile;