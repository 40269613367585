import styled from "styled-components";
import { Link } from 'react-router-dom';

export const Banner = styled.div`
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const TxtContainer = styled.div`
  text-align: center;
`;

export const Text = styled.p`
  color: white;
  font-size: 20px;
`;

export const PlayerContainer = styled.div`
  text-align: center;
`;

export const Player = styled.iframe`
  width: 800px;
  height: 500px;

  @media screen and (max-width: 769px) {
    /* Estilos para telas menores que 768px (exemplo: dispositivos móveis em modo paisagem) */
    width: 600px;
    height: 400px;
  }

  @media screen and (max-width: 480px) {
    /* Estilos para telas menores que 481px (exemplo: dispositivos móveis em modo retrato) */
    width: 400px;
    height: 250px;
  }
`;

export const Button = styled(Link)`
    margin-bottom: 10px;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    
    &:hover {
        background-color: #0062cc;
    }
`;

export const BtnPreviouNext = styled.button`
  padding: 10px;
  margin: 5px;
  background-color: #eee;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;