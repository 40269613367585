import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Banner = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 480px) {
    /* Estilos para telas menores que 481px (exemplo: dispositivos móveis em modo retrato) */
    height: 200px;
  }
`;

export const BannerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MovieTitle = styled.h2`
  font-size: 36px;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
  text-align: center;

  @media screen and (max-width: 480px) {
    /* Estilos para telas menores que 481px (exemplo: dispositivos móveis em modo retrato) */
    font-size: 26px;
    margin-bottom: 10px;
  }
`;

export const MovieButton = styled(Link)`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #0070f3;
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  margin-top: 10px;

  @media screen and (max-width: 480px) {
    /* Estilos para telas menores que 481px (exemplo: dispositivos móveis em modo retrato) */
    font-size: 14px;
  }
`;