import styled from "styled-components";

export const Container = styled.div`
  background-color: #1C1C1C;
`;

export const Header = styled.h1`
  text-align: center;
  margin-top: 0px;
  margin-bottom: 20px;
  color: white;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TextContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 2;
  h2 {
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  }
`;