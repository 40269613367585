import React, {useEffect, useState} from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Banner, Container, TxtContainer, Text, PlayerContainer, Player, Button, BtnPreviouNext } from "./style";

function SeriePlayer() {

    const {id, temp, epid} = useParams();
    const navigate = useNavigate();

    const [listSerie, setListSerie] = useState([]);
    const [listEp, setListEp] = useState([]);
    const [currentEpisode, setCurrentEpisode] = useState(epid);
    const [totalEps, setTotalEps] = useState(0);

    useEffect(() => {
        const fetchLists = async (id, temp, epid) => {
            const selectedOption = temp + " Temporada";
            const seriesInfo = await axios.post(`${process.env.REACT_APP_API_BASE_LIONFLIX}/series/lista`, {
                id: id
            },{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            setListSerie(seriesInfo.data);

            const seriesEps = await axios.post(`${process.env.REACT_APP_API_BASE_LIONFLIX}/series/episodios`, {
                id: id
            },{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }); 

            const eps = seriesEps.data;
            const epsFiltrados = eps.filter(ep => ep.Temporadas.includes(selectedOption));
            const epfilter = epsFiltrados.filter(ep => ep.Ep === epid);

            setTotalEps(epsFiltrados.length);
            setListEp(epfilter);
        };

        fetchLists(id, temp, epid);

    },[id, temp, epid]);

    const handlePrevious = () => {
        if (parseInt(currentEpisode) > 1) {
          setCurrentEpisode(parseInt(currentEpisode) - 1);
          navigate(`/serie/${id}/temporada/${temp}/episodio/${parseInt(currentEpisode) - 1}`);
        }
    };
    
    const handleNext = () => {
        if (parseInt(currentEpisode) < parseInt(totalEps)) {
            setCurrentEpisode(parseInt(currentEpisode) + 1);
            navigate(`/serie/${id}/temporada/${temp}/episodio/${parseInt(currentEpisode) + 1}`);
        }
    };    

    const eps = listEp?.map((index, key) =>
        <div>
            <Text>{index.Ep} - {index.Nome_Ep} ({index.Temporadas})</Text> 
            <Player key={key} src={index.Link} frameBorder='0' allowFullScreen></Player>
        </div>
    );    

    return(
        listSerie?.map((index, key) => 
            <Banner key={key} style={{ backgroundImage: `url(https://image.tmdb.org/t/p/original${index.banner})` }} >
                <Container>
                    <TxtContainer>
                        <Button to={`/serie/${id}`} >Retornar para {index.Nome}</Button>
                    </TxtContainer>
                    <PlayerContainer>
                        {eps}
                        <BtnPreviouNext onClick={handlePrevious} disabled={parseInt(currentEpisode) === 1}>
                            Anterior
                        </BtnPreviouNext>
                        <BtnPreviouNext  onClick={handleNext} disabled={parseInt(currentEpisode) === parseInt(totalEps)}>
                            Proximo
                        </BtnPreviouNext>                        
                    </PlayerContainer>
                </Container>
            </Banner>
        )  
    );
}

export default SeriePlayer;