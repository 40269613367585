import styled from "styled-components";

export const Container = styled.div`
  background-color: #1C1C1C;
`;

export const Header = styled.h1`
  text-align: center;
  margin-top: 0px;
  margin-bottom: 20px;
  color: white;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const MovieCard = styled.div`
  border: 0;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const MovieTitle = styled.h2`
  margin-bottom: 8px;
`;

export const MovieImage = styled.img`
  width: 200px;
  height: 300px;
  object-fit: cover;

  @media screen and (max-width: 480px) {
    /* Estilos para telas menores que 481px (exemplo: dispositivos móveis em modo retrato) */
    width: 90px;
    height: 120px;
  }
`;

export const Centerbtn = styled.div`
  text-align: center;
  padding: 8px;
`;

export const BtnNP = styled.button`
  padding: 8px;
  margin-left: 2%;
  border: 0;
`;