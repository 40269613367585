import React, { useState, useEffect } from 'react';
import { SearchBoxWrapper, SearchInput, Header, Content, MovieCard, MovieImage, MovieTitle } from './style';
import axios from 'axios';
import { Link } from 'react-router-dom';

const SearchBox = () => {
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);

  const handleSearchTextChange = event => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);

    // Cancela o timeout atual (se houver um) para evitar buscas desnecessárias
    if (timeoutId) {
        clearTimeout(timeoutId);
    }

    // Define um novo timeout para realizar a busca após 500ms
    const newTimeoutId = setTimeout(async () => {

        if (searchText.length > 0) {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_LIONFLIX}/filmes/buscar`,
            {filme: searchText},{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            const data = await response.data;
            
            const results = data.map((result) => ({
                nome: result.Nome,
                idTmdb: result.idtmdb,
                foto: result.foto 
            }));

            setSearchResults(results);
        }
    }, 500);

    setTimeoutId(newTimeoutId);
  };

  const movieSearch = searchResults?.map((index, key) =>
    <Link to={`${index.idTmdb}`} key={key}>
    <MovieCard key={key}>
        <MovieImage src={`https://image.tmdb.org/t/p/w200/${index.foto}`} alt={index.nome} />
    </MovieCard>
    </Link>
  );

  return (
    <SearchBoxWrapper>
        <Header>Buscar Filmes</Header>
        <SearchInput type="text" placeholder="Digite o nome do filme" value={searchText} onChange={handleSearchTextChange} />
        <Content>
            {movieSearch}
        </Content>
    </SearchBoxWrapper>
  );
};

export default SearchBox;