import React, { useEffect } from 'react';
import localforage from 'localforage';
import axios from 'axios';

function TaskCron() {

  useEffect(() => {

    const fetchRandMovies = async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_LIONFLIX}/filmes/randomvarious`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      localforage.setItem('listRandMovies', JSON.stringify(response.data));
    };

    fetchRandMovies();

    const interval = setInterval(() => {
      fetchRandMovies();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

}

export default TaskCron;