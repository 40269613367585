import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Banner, BannerOverlay, MovieTitle, MovieButton } from './style';

const MovieBanner = () => {
  const [movie, setMovie] = useState(null);

  useEffect(() => {
    const fetchMovie = async () => {

      const response = await axios.get(`${process.env.REACT_APP_API_BASE_LIONFLIX}/filmes/random`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      const movies = response.data || [];
      
      const randomIndex = Math.floor(Math.random() * movies.length);
      const randomMovie = movies[randomIndex];
      setMovie(randomMovie);
    };

    fetchMovie();

    const interval = setInterval(() => {
        fetchMovie();
    }, 30000);
    return () => clearInterval(interval);    
  }, []);

  return (
    <Banner style={{ backgroundImage: `url(https://image.tmdb.org/t/p/original/${movie?.Backdrop})` }}>
      {movie && (
        <BannerOverlay>
          <MovieTitle>{movie.NameMovie}</MovieTitle>
          <MovieButton to={`filme/${movie.Id_Tmdb}`} >Assistir Agora</MovieButton>
        </BannerOverlay>
      )}
    </Banner>
  );
};

export default MovieBanner;