import React, { useEffect, useState } from 'react';
import { HeaderWrapper, Logo, Navigation, NavLink } from './style';
import axios from 'axios';
import { BiHomeAlt2 } from "react-icons/bi";
import { GiPerspectiveDiceSixFacesRandom } from "react-icons/gi";
import { BsFillGridFill } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import localforage from 'localforage';
import { isMobile } from 'react-device-detect';
import { BsCollectionPlayFill } from 'react-icons/bs';

function Header() {

  const [movie, setMovie] = useState(null);
  const [sizeIcon, setSizeIcon] = useState(30);

  useEffect(() => {

    {isMobile ? setSizeIcon(25) : setSizeIcon(30)}

    const fetchMovie = async () => {

      const listRandMovies = await localforage.getItem('listRandMovies');

      const movies = JSON.parse(listRandMovies);
      const randomIndex = Math.floor(Math.random() * movies.length);
      const randomMovie = movies[randomIndex];
      setMovie(randomMovie);
    };


    const fetchList = async () => {

        //Buscar as informaçoes dos filmes e dos generos no tmdb
        const genresTmdb = await axios.get(`${process.env.REACT_APP_API_BASE_TMDB}/genre/movie/list?language=pt-BR`, {
            headers: {
              'Authorization': process.env.REACT_APP_API_AUTH_TMDB,
              'accept': 'application/json'
            }
        });

        const listGenres = genresTmdb.data.genres;

        const moviesforGenres = [];

        for (const genre of listGenres) {
            const moviesTmdb = await axios.post(`${process.env.REACT_APP_API_BASE_LIONFLIX}/filmes/genres`,{
                genre: genre.id
            }, {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            if (moviesTmdb.data.length > 0) {
                // Adicione o primeiro filme do gênero à lista de filmesPorGenero
                moviesforGenres.push({
                //   genre: genre.name,
                //   movie: moviesTmdb.data[0],
                  generoID: genre.id,
                  generoName: genre.name,
                  filmes: moviesTmdb.data[0].Backdrop                      
                });
            }
        }

        localforage.setItem('cacheMovies', JSON.stringify(moviesforGenres));
    };    

    fetchMovie();
    fetchList();

    const interval = setInterval(() => {
      fetchMovie();
    }, 1000);
    return () => clearInterval(interval);   
  }, []);


  return (
    <HeaderWrapper>
      <Logo>LIONFLIX</Logo>
      <Navigation>
        <NavLink to="/"><BiHomeAlt2 size={sizeIcon} /></NavLink>
        <NavLink to="/categorias"><BsFillGridFill size={sizeIcon} /></NavLink>
        <NavLink to={`filme/${movie?.Id_Tmdb}`}><GiPerspectiveDiceSixFacesRandom size={sizeIcon} /></NavLink>
        <NavLink to="/colecoes"><BsCollectionPlayFill size={sizeIcon} /></NavLink>
        <NavLink to="/buscar"><FiSearch size={sizeIcon} /></NavLink>
      </Navigation>
    </HeaderWrapper>
  );
}

export default Header;