import React, {useEffect, useState} from "react";
import axios from "axios";
import { 
    Banner, Container, ImageContainer, Image, 
    ButtonList, Button, Dropdown, 
    ContainerDrop, Label, Text, Overview,
    ContainerInfo, Player, ContainerPlayer,
    BtnPc
} from  './style';
import { useParams } from "react-router-dom";
import { isMobile } from 'react-device-detect';


function EpisodeSeries() {

    const { id } = useParams();

    const [listSerie, setListSerie] = useState([]);
    const [listEps, setListEps] = useState([]);
    const [info, setInfo] = useState([]);
    const [selectedOption, setSelectedOption] = useState('1 Temporada');
    const [epFiltered, setEpFilter] = useState([]);

    function handleOptionChange(event) {
        setSelectedOption(event.target.value);
    }

    useEffect(() => {
        const fetchLists = async (id, selectedOption) => {
            const seriesInfo = await axios.post(`${process.env.REACT_APP_API_BASE_LIONFLIX}/series/lista`, {
                id: id
            },{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            setListSerie(seriesInfo.data);

            const seriesEps = await axios.post(`${process.env.REACT_APP_API_BASE_LIONFLIX}/series/episodios`, {
                id: id
            },{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }); 

            const eps = seriesEps.data;
            const epsFiltrados = eps.filter(ep => ep.Temporadas.includes(selectedOption));

            setListEps(epsFiltrados);

            const inf = await axios.get(`${process.env.REACT_APP_API_BASE_TMDB}/tv/${id}?language=pt-BR`, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_AUTH_TMDB,
                    'accept': 'application/json'
                }
            });

            const serieData = inf.data;
            setInfo(serieData);
        };

        fetchLists(id, selectedOption);

    },[id, selectedOption]);

    const handleButtonClick = (event) => {
        const buttonId = event.target.id;

        const epfilter = listEps?.filter(ep => ep.Ep == buttonId);

        setEpFilter(epfilter);
    };

    const optionArray = [];
    listSerie?.forEach(element => {
        for (let index = 0; index < element.Total_Temporadas; index++) {
            optionArray.push({id: index + 1 + " Temporada"});
        }
    });

    const eps = listEps?.map((index, key) => 
        <Button key={key} to={`/serie/${id}/temporada/${selectedOption.charAt(0)}/episodio/${key + 1}`} >{index.Nome_Ep}</Button>
    );

    const eps2 = listEps?.map((index, key) => 
        <BtnPc key={key} id={key + 1} onClick={handleButtonClick}>{index.Nome_Ep}</BtnPc>
    );

    const option = optionArray?.map((index, key) => 
        <option key={key} value={index.id}>{index.id}</option>
    );

    const epfill = epFiltered?.map((index, key) =>
        <div>
            <Text>{index.Ep} - {index.Nome_Ep} ({index.Temporadas})</Text>  
            <Player key={key} src={index.Link} frameBorder='0' allowFullScreen></Player>
        </div>
    );

    return(
        listSerie?.map((index, key) => 
        <Banner key={key} style={{ backgroundImage: `url(https://image.tmdb.org/t/p/original${index.banner})` }} >
            <Container>
                <ImageContainer>
                    <Image src={`https://image.tmdb.org/t/p/w200${index.foto}`} alt={`${index.Nome}`} />
                    {(index.Idade === "Livre") ? <Text>{index.Nome} ({index.Idade})</Text> : <Text>{index.Nome} (+{index.Idade})</Text>}
                    <Overview>{info.overview}</Overview>
                </ImageContainer>         
                <ContainerInfo>
                    <ContainerDrop>
                        <Label htmlFor="dropdown">Selecione uma opção:</Label>
                        <Dropdown id="dropdown" value={selectedOption} onChange={handleOptionChange}>
                            {option}
                        </Dropdown>
                    </ContainerDrop> 
                    <ButtonList>
                    {isMobile ? eps : eps2}
                    </ButtonList>
                </ContainerInfo>
                <ContainerPlayer>
                    {isMobile ? "" : epfill}
                </ContainerPlayer>        
            </Container>
        </Banner>
        )
    );
}

export default EpisodeSeries;