import styled from 'styled-components';

export const FooterContainer = styled.footer`
  background-color: #080808;
  color: #fff;
  padding: 20px;
  text-align: center;
`;

export const CreatedBy = styled.p`
  font-size: 14px;
`;