import styled from "styled-components";
import { Link } from 'react-router-dom';

export const Banner = styled.div`
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

export const Container = styled.div`
    display: flex;
    //justify-content: space-evenly;
    //align-items: center;
    //padding: 20px;
    flex-direction: row;
    background-color: rgba(0, 0, 0, 0.6);
`;

export const ImageContainer = styled.div`
    flex: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: rgba(17, 17, 17, 0.7);
`;

export const Image = styled.img`
    width: 200px;
    height: 275px;
    object-fit: cover;
    padding: 2px;

    @media screen and (max-width: 480px) {
        /* Estilos para telas menores que 481px (exemplo: dispositivos móveis em modo retrato) */
        width: 100px;
        height: 137px;
    }
`;

export const Text = styled.p`
    color: white;
    font-size: 18px;
    text-align: center;
`;

export const Overview = styled.p`
    color: white;
    font-size: 12px;
    text-align: justify;
`;

export const ButtonList = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 600px;
    overflow-y: scroll;

    @media screen and (max-width: 800px) {
        /* Estilos para telas menores que 768px (exemplo: dispositivos móveis em modo paisagem) */
        height: 350px;
    }
    
    @media screen and (max-width: 480px) {
        /* Estilos para telas menores que 481px (exemplo: dispositivos móveis em modo retrato) */
        height: 100vh;
    }
`;

export const Button = styled(Link)`
    margin-bottom: 10px;
    padding: 10px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    
    &:hover {
        background-color: #888;
    }
`;

export const BtnPc = styled.button`
    margin-bottom: 10px;
    padding: 10px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    
    &:hover {
        background-color: #888;
    }
`;

export const ContainerInfo = styled.div`
    flex-direction: column;
    padding: 10px;
    background-color: rgba(17, 17, 17, 0.5);

    @media screen and (max-width: 480px) {
        /* Estilos para telas menores que 481px (exemplo: dispositivos móveis em modo retrato) */
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-right: -10%;
    }
`;

export const ContainerDrop = styled.div`
    padding: 10px;
`;

export const Dropdown = styled.select`
    font-size: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;

    &:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
    }

    @media screen and (max-width: 480px) {
        /* Estilos para telas menores que 481px (exemplo: dispositivos móveis em modo retrato) */
        font-size: 14px;
    }
`;

export const Label = styled.label`
    color: white;
`;

export const ContainerPlayer = styled.div`
    flex: 0;
    align-items: center;
    justify-content: center;
    display: flex;   
    padding: 20px; 
`;

export const Player = styled.iframe`
  width: 800px;
  height: 500px;

  @media screen and (max-width: 769px) {
    /* Estilos para telas menores que 768px (exemplo: dispositivos móveis em modo paisagem) */
    width: 600px;
    height: 400px;
  }

  @media screen and (max-width: 480px) {
    /* Estilos para telas menores que 481px (exemplo: dispositivos móveis em modo retrato) */
    width: 400px;
    height: 250px;
  }
`;