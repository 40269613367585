import styled from 'styled-components';
import { keyframes } from 'styled-components';

const slideInRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const MovieImg = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 90vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;   
`;

export const ContainerMovieInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Header = styled.h2`
    color: white;
`;

export const MoviePlayerLayout = styled.div`
  animation: ${slideInRight} 0.5s ease;
  display: ${props => props.visible ? 'flex' : 'none'};
  transform: ${props => props.visible ? 'translateX(2%)' : 'translateX(0)'};
`;

export const Player = styled.iframe`
  width: 800px;
  height: 500px;

  @media screen and (max-width: 769px) {
    /* Estilos para telas menores que 768px (exemplo: dispositivos móveis em modo paisagem) */
    width: 600px;
    height: 400px;
  }

  @media screen and (max-width: 480px) {
    /* Estilos para telas menores que 481px (exemplo: dispositivos móveis em modo retrato) */
    width: 400px;
    height: 250px;
  }
`;

export const MoviePanel = styled.div`
  display: flex;
  height: 100%;
  width: 40%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.6); 
  animation: ${slideInRight} 0.5s ease;
  transform: ${props => props.animate ? 'translateX(-5%)' : 'translateX(0)'};
  transition: left 0.5s ease;
`;

export const MovieImage = styled.img`
  max-width: 40%;
`;

export const MovieDescription = styled.p`
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
  text-align: justify;
`;

export const WatchButton = styled.button`
  margin-top: 20px;
  padding: 5px 70px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;

  &:hover {
    background-color: #0056b3;
  }
`;