import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { HomeWrapper } from './style';
import Lists from '../../components/TopRecents/Lists';
import MovieBanner from '../../components/MovieBanner';

function Home() {

  const [movies, setMovies] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    const fetchMovies = async () => {

      const movietopTen = await axios.get(`${process.env.REACT_APP_API_BASE_LIONFLIX}/filmes/moviestopten`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      setMovies(movietopTen.data);

      const serieList = await axios.get(`${process.env.REACT_APP_API_BASE_TMDB}/list/${process.env.REACT_APP_API_ID_LIST_SERIES}?language=pt-BR`, {
          headers: {
            'Authorization': process.env.REACT_APP_API_AUTH_TMDB,
            'accept': 'application/json'
          },
      });

      const newSeries = serieList.data.items.reverse();
      setSeries(newSeries);
    };

    fetchMovies();
  }, []);

  
  return (
    <HomeWrapper>
      <MovieBanner />
      <Lists list={movies} />
      <Lists list={series} />
    </HomeWrapper>
  );
}

export default Home;