import styled from 'styled-components';

export const SearchBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;  
  margin-top: 0;
  padding: 2%;
  background-color: #1C1C1C;
  flex-direction: column;
`;

export const Header = styled.h2`
    color: white
`;

export const SearchInput = styled.input`
  padding: 10px;
  font-size: 18px;
  border: none;
  border-bottom: 2px solid #ccc;
  width: 500px;
  text-align: center;

  @media screen and (max-width: 769px) {
    /* Estilos para telas menores que 768px (exemplo: dispositivos móveis em modo paisagem) */
    width: 400px;
  }

  @media screen and (max-width: 480px) {
    /* Estilos para telas menores que 481px (exemplo: dispositivos móveis em modo retrato) */
    width: 200px;
  }

  &:focus {
    outline: none;
    border-bottom-color: #00bcd4;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const MovieCard = styled.div`
  border: 0;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const MovieTitle = styled.h2`
  margin-bottom: 8px;
`;

export const MovieImage = styled.img`
  width: 200px;
  height: 300px;
  object-fit: cover;
  
  @media screen and (max-width: 480px) {
    /* Estilos para telas menores que 481px (exemplo: dispositivos móveis em modo retrato) */
    width: 90px;
    height: 120px;
  }
`;