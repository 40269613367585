import React, {useEffect, useState} from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Container, Header, Content, MovieCard, MovieImage, MovieTitle, Centerbtn, BtnNP } from './style';
import { Link } from "react-router-dom";

function MoviesForGenres() {

    const { idgenero } = useParams();

    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        
        const fetchList = async (id) => {

            //Buscar as informaçoes dos filmes e dos generos no tmdb
            const genresTmdb = await axios.get(`${process.env.REACT_APP_API_BASE_TMDB}/genre/movie/list?language=pt-BR`,
            {
                headers: {
                  'Authorization': process.env.REACT_APP_API_AUTH_TMDB,
                  'accept': 'application/json'
                }
            }
            );

            const moviesTmdb = await axios.post(`${process.env.REACT_APP_API_BASE_LIONFLIX}/filmes/moviesforgenres`,{
                genre: id,
                page: page,
            }, {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            const listMovies = moviesTmdb.data.items || [];
            const listGenres = genresTmdb.data.genres || [];
            
            const generosArray = [];

            // Itera sobre cada gênero
            listGenres?.forEach(genero => {
                // Filtra os filmes que pertencem ao gênero atual
                if (genero.id == id) {
                    const filmesFiltrados = listMovies.filter(filme => filme.Genres.includes(genero.id));
                    // Se houver pelo menos um filme, adiciona o gênero ao array de gêneros
                    if (filmesFiltrados.length > 0) {

                        const filmes = filmesFiltrados.map((filme) => {
                            return { 
                                id: filme.Id_Tmdb,
                                nome: filme.NameMovie,
                                poster: filme.Poster,
                            };
                        });

                        generosArray.push({
                            generoName: genero.name,
                            filmes: filmes,
                        });

                    }
                }
            });

            if (generosArray.length > 0) {
                setList(generosArray);
                setTotalPages(moviesTmdb.data.totalPages);
            }
        };

        fetchList(idgenero);        

    },[idgenero, page, totalPages]);

    const head = list?.map((index, key) => 
        <Header key={key}>{index.generoName}</Header>
    );

    // const sortedMovies = list[0].filmes;

    const movies = list?.map((mv) => 
        mv.filmes.map((index, key) =>
            <Link to={`${index.id}`} key={key}>
                <MovieCard key={key}>
                    <MovieImage src={`https://image.tmdb.org/t/p/w200/${index.poster}`} alt={index.nome} />
                </MovieCard>
            </Link>
        )
    );

    return(
       <Container>
            {head}
            <Content>
                {movies}               
            </Content>
            <Centerbtn>
                {page > 1 && (
                    <BtnNP onClick={() => setPage(page - 1)}>Página Anterior</BtnNP>
                )}

                {page < totalPages && (
                    <BtnNP onClick={() => setPage(page + 1)}>Próxima Página</BtnNP>
                )} 
            </Centerbtn>
       </Container> 
    );   
}

export default MoviesForGenres;