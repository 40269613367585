import React, {useEffect, useState} from 'react';
import { Container, Player, MovieImg, Header, MoviePanel, MovieImage, MovieDescription, WatchButton, MoviePlayerLayout, ContainerMovieInfo } from './style';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BiPlayCircle } from 'react-icons/bi';
import { isMobile } from 'react-device-detect';

const MoviePlayer = () => {

    const { id } = useParams();

    const [ movieLF, setMovieLF ] = useState([]);
    const [ movieTMDB, setMovieTMDB ] = useState([]);
    const [animatePanel, setAnimatePanel] = useState(false);

    const handleWatchClick = () => {
      setAnimatePanel(true);
    };

    useEffect(() => {
        const ident = id;

        const fetchMoviePlayer = async () => {
          
          const responseLF = await axios.post(`${process.env.REACT_APP_API_BASE_LIONFLIX}/filmes/tmdb`, {
              id: ident
            }, {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
          });         
          
          setMovieLF(responseLF?.data[0]);

          const responseTmdb = await axios.get(`${process.env.REACT_APP_API_BASE_TMDB}/movie/${ident}?language=pt-BR`,
          {
            headers: {
              'Authorization': process.env.REACT_APP_API_AUTH_TMDB,
              'accept': 'application/json'
            }
          }
          );
          setMovieTMDB(responseTmdb.data);

        };

        fetchMoviePlayer();
    },[id]);

    return (
      <Container>
        <MovieImg style={{backgroundImage: `url(https://image.tmdb.org/t/p/original${movieTMDB?.backdrop_path})`}}>
          {isMobile ?
          <>
            {(movieLF.Idade === "Livre") ? <Header>{movieLF.Nome} ({movieLF.Idade})</Header> : <Header>{movieLF.Nome} (+{movieLF.Idade})</Header>}
            <Player src={movieLF.Link} frameBorder='0' allowFullScreen></Player>
          </>
          : 
          <ContainerMovieInfo>
            <MoviePanel animate={animatePanel}>
              {(movieLF.Idade === "Livre") ? <Header>{movieLF.Nome} ({movieLF.Idade})</Header> : <Header>{movieLF.Nome} (+{movieLF.Idade})</Header>}
              <MovieImage src={`https://image.tmdb.org/t/p/w185${movieTMDB?.poster_path}`} alt={movieLF.Nome} />
              <WatchButton onClick={handleWatchClick}><BiPlayCircle size={30} /></WatchButton>
              <MovieDescription>{movieTMDB?.overview}</MovieDescription>
            </MoviePanel>
            <MoviePlayerLayout visible={animatePanel}>
              <Player src={movieLF.Link} frameBorder='0' allowFullScreen></Player>
            </MoviePlayerLayout>            
          </ContainerMovieInfo>
          }
        </MovieImg>
      </Container>
    );
};

export default MoviePlayer;