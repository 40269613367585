import styled from 'styled-components';

export const MovieListWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 1rem;
  scroll-behavior: smooth;
  
  /* Esconde a barra de rolagem em navegadores WebKit */
  ::-webkit-scrollbar {
    display: none;
  }  
`; 

export const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  z-index: 1;
  ${({ direction }) => (direction === 'left' ? 'left: 0;' : 'right: 0;')}
`;