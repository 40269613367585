import React from 'react';
import { MovieCardWrapper, MovieImage } from './style';
import { Link } from 'react-router-dom';

function Cards({ item }) {
    const posterUrl = item.NameMovie ? `https://image.tmdb.org/t/p/w200/${item.Poster}` : `https://image.tmdb.org/t/p/w200/${item.poster_path}`;

    const nome = item.NameMovie ? item.NameMovie : item.name;
    const link = item.NameMovie ? `filme/${item.Id_Tmdb}` : `serie/${item.id}`;

    return (
    <MovieCardWrapper>
        <Link to={link}>
            <MovieImage src={posterUrl} alt={nome} />
        </Link>
    </MovieCardWrapper>
    );
}

export default Cards;