import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Container, Header, Content, ImageContainer, Image, TextContainer } from './style';

function Collection() {

    const [collections, setCollections] = useState([]);

    useEffect(() => {
        const fetchCollections = async () => {
            const collection = await axios.get(`${process.env.REACT_APP_API_BASE_LIONFLIX}/filmes/collection`, {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            const response = collection.data;
            setCollections(response);
        };
        
        fetchCollections();

        const interval = setInterval(() => {
            fetchCollections();
        }, 10000);
        return () => clearInterval(interval); 
    }, []);

    const categorias = collections?.map((items, key) =>
        <Link to={`${key + 1}`} key={key} className="table-button table-oppacity-button">
            <ImageContainer>
                <Image key={key} src={`https://image.tmdb.org/t/p/w400${items.info[Math.floor(Math.random() * items.info.length)].Backdrop}`}/>
                <TextContainer>
                    <h2>{items.title}</h2>
                </TextContainer>
            </ImageContainer>
        </Link>
    );

    return (
        <Container>
            <Header>Coleções</Header>
            <Content>
                {categorias}
            </Content>
        </Container>
    );
}

export default Collection;