import React, {useEffect, useState} from "react";
import axios from "axios";
import { 
    Banner, Container, ImageContainer, Image, 
    ButtonList, Text, Overview,
    ContainerInfo, Player, ContainerPlayer,
    Button, BtnPc
} from  './style';
import { useParams } from "react-router-dom";
import { isMobile } from 'react-device-detect';


function EpisodeSeries() {

    const { id } = useParams();

    const [listMovie, setListMovie] = useState([]);
    const [currentMovie, setCurrentMovie] = useState([]);
    const [selectedOption, setSelectedOption] = useState(0);

    useEffect(() => {
        const fetchLists = async (id, selected) => {
            const collection = await axios.get(`${process.env.REACT_APP_API_BASE_LIONFLIX}/filmes/collection`, {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            const response = collection.data[id - 1];

            setListMovie(response?.info);
            setCurrentMovie(response?.info[selected]);
        };

        fetchLists(id, selectedOption);

    },[id, selectedOption]);

    const handleButtonClick = (event) => {
        const buttonId = event.target.id;
        setSelectedOption(buttonId);
    };

    const mv = listMovie?.map((index, key) => 
        <Button key={key} to={`/colecoes/m/${id}/${key}`}>{index.NameMovie}</Button>
    );

    const mv2 = listMovie?.map((index, key) => 
        <BtnPc key={key} id={key} onClick={handleButtonClick}>{index.NameMovie}</BtnPc>
    );

    return(
        <Banner style={{ backgroundImage: `url(https://image.tmdb.org/t/p/original${currentMovie?.Backdrop})` }}>
            <Container>
                <ImageContainer>
                    <Image src={`https://image.tmdb.org/t/p/w200${currentMovie?.Poster}`} alt={`${currentMovie?.NameMovie}`} />
                    {(currentMovie?.Idade === "Livre") ? <Text>{currentMovie?.NameMovie} ({currentMovie?.Idade})</Text> : <Text>{currentMovie?.NameMovie} (+{currentMovie?.Idade})</Text>}
                    <Overview>{currentMovie?.Overview}</Overview>
                </ImageContainer>
                <ContainerInfo>
                    <ButtonList>
                       {isMobile ? mv : mv2}
                    </ButtonList>
                </ContainerInfo>
                {isMobile ? "" :
                <ContainerPlayer>
                    <Text>{currentMovie?.NameMovie}</Text>  
                    <Player src={currentMovie?.LinkMovie} frameBorder='0' allowFullScreen></Player>
                </ContainerPlayer>
                }
            </Container>
        </Banner>
    );
}

export default EpisodeSeries;